<!-- src/components/Footer.vue -->
<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <a
            href="https://www.instagram.com/atrezzoproductora/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="bi bi-instagram"></i> Instagram
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
  
  <script>
export default {
  name: "AppFooter",
};
</script>
  
  <style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #17243d;
  padding: 20px 0;
  color: white;
  font-size: 1rem;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  color: #f0f0f0;
  text-decoration: none;
}

.bi {
  margin-right: 5px;
}
</style>
  