
import { importUlisesImages } from "../data/images";
import { importCosquinRockImages } from "../data/images";
import { importManantialesImages } from "../data/images";
import { importBackstageImages } from "../data/images";

const ulisesImages = importUlisesImages();
console.log("Ulises Images in albums.js:", ulisesImages);
const backstageImages = importBackstageImages();
console.log("Backstage Images in albums.js:", backstageImages);
const cosquinRockImages = importCosquinRockImages();
console.log("Cosquin Rock Images in albums.js:", cosquinRockImages);
const manantialesImages = importManantialesImages();
console.log("Manantiales Images in albums.js:", manantialesImages);


const albums = [
        {
          title: "Ulises - Barcelona",
          thumbnail: ulisesImages["ulises_bueno_5"],
          images: Object.values(ulisesImages),
        },
        {
          title: "Cosquin Rock",
          thumbnail: cosquinRockImages["cosquin_rock_17"],
          images: Object.values(cosquinRockImages),
        },
        {
          title: "Manantiales Day",
          thumbnail: manantialesImages["manantiales_day_1"],
          images: Object.values(manantialesImages),
        },
        {
          title: "Backstage Videoclip",
          thumbnail: backstageImages["back_10"],
          images: Object.values(backstageImages),
        },
  ];

  export default albums;