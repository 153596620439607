<template>

  <div class="container">
    <router-link to="/">
      <button type="button" class="btn btn-outline-secondary">
        Volver
      </button>
    </router-link>
    <h2>{{ propuesta.title }}</h2>
    <div class="row mt-5">
      <div class="col-md-4 mb-4" v-for="image in propuesta.images" :key="image">
        <img :src="image" class="img-fluid" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import propuesta from '../data/propuesta.js';

export default {
    name: "PurposalPage",
  data() {
    return {
      propuesta: propuesta,
      
    };
  },
  created() {
console.log(this.propuesta);
  }
}
</script>

<style scoped>
.btn {
  margin: 20px 0 20px 3px;
}
  .logo {
    margin: 0 auto;
    text-align: center;
    max-width: 300px;
    margin-bottom: 50px;
  }
</style>