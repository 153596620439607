import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Photos from '../components/Photos.vue';
import Videos from '../components/Videos.vue';
import AlbumDetails from '../components/AlbumDetails';
import Clients from '../components/Clients';
import Purposal from '../components/Purposal';



const routes = [
  { path: '/', component: Home }, 
  { path: '/photos', component: Photos },
  { path: '/videos', component: Videos },
  { path: '/clients', component: Clients },
  { path: '/clients', component: Clients },
  { path: '/purposal', component: Purposal },
  { path: '/photos/:id', component: AlbumDetails, name: 'Album'},
  { path: '/:pathMatch(.*)*', redirect: '/' }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;