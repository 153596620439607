<template>
  <div class="container text-center">
    <a href="http://" target="_blank" rel="noopener noreferrer">
      <img class="logo" :src="logoAtrezzo" alt="" />
    </a>
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <router-link to="/photos" class="text-decoration-none">
          <div class="card bg-card text-white">
            <img :src="photosImage" class="card-img" alt="Fotos" />
            <div
              class="card-img-overlay d-flex align-items-center justify-content-center">
              <h2>Fotos</h2>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <router-link to="/videos" class="text-decoration-none">
          <div class="card bg-card text-white">
            <img :src="videosImage" class="card-img" alt="Videos" />
            <div
              class="card-img-overlay d-flex align-items-center justify-content-center">
              <h2>Videos</h2>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 col-md-6">
        <a
          target="_blank"
          href="https://drive.google.com/file/d/1pyegcx6PcngdxT1HlA5dFiZw1dUDwGTV/view?usp=sharing"
          class="text-decoration-none">
          <div class="card bg-card text-white">
            <img :src="propuestaImage" class="card-img" alt="Fotos" />
            <div
              class="card-img-overlay d-flex align-items-center justify-content-center">
              <h2>Propuesta</h2>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-12">
  <div class="card bg-card text-white">
    <div class="card-body">
      <!-- <h2 class="text-center mb-4">Nuestros Clientes</h2> -->
      <Swiper :slides-per-view="1" :loop="true" :autoplay="{ delay: 1000 }" class="clients-swiper">
        <SwiperSlide v-for="(logo, index) in logos" :key="index" class="d-flex align-items-center justify-content-center">
          <img :src="logo.src" name="logos" :alt="logo.alt" class="logo-slide" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</div>
    </div>
  </div>
</template>
  
  <script>
import photosImage from "../assets/cosquinRock/cosquin_rock_1.jpg";
import logoAtrezzo from "../assets/logo-atrezzo.png";
import propuestaImage from "../assets/Manantiales/manantiales_day_1.jpg";
import { Swiper, SwiperSlide } from "swiper/vue";
import logos_1 from "../assets/logos_clientes/marcas_2.jpg";
import logos_2 from "../assets/logos_clientes/marcas.jpg";


export default {
  name: "HomePage",
  data() {
    return {
      photosImage,
      propuestaImage,
      videosImage: "https://img.youtube.com/vi/GFGoBAo2lkI/0.jpg",
      logoAtrezzo,
      Swiper,
      SwiperSlide,

      images: [
        { src: "https://picsum.photos/id/1000/1000/600", alt: "Imagen 1" },
        { src: "https://picsum.photos/id/1001/1000/600", alt: "Imagen 2" },
        { src: "https://picsum.photos/id/1002/1000/600", alt: "Imagen 3" },
      ],
      logos: [
        {
          src: logos_1,
          alt: "Logo 1",
        },
        {
          src: logos_2,
          alt: "Logo 2",
        },
        // {
        //   src: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png",
        //   alt: "Logo 3",
        // },
      ],
    };
  },
};
</script>
  
  <style scoped>
  
.img-fluid {
  width: 100%;
  height: auto;
}
.logo {
  max-width: 300px;
  margin: 20px 0 30px 0;
}
.card-img {
  filter: brightness(0.4);
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.bg-card {
  transition: transform 0.3s;
  margin-bottom: 30px;
}

.bg-card:hover {
  transform: scale(1.05);
}

.logo-slide {
  max-width: 93%;
  height: 200px;
  object-fit: cover; /* Cambia a contain o cover para mantener las proporciones */
  padding: 5px;
  margin: 0;
}

.clients-swiper {
  background-color: #30394a1a;
  padding: 20px 0;
  border-radius: 5px;
  height: 220px;
  display: flex;
  align-items: center;
}



@media (min-width: 768px) {
  .clients-swiper {
    height: 400px; /* Aumenta la altura del contenedor del slider para pantallas medianas y mayores */
  }

  .logo-slide {
    max-height: 400px; /* Aumenta la altura máxima de las imágenes del slider para pantallas medianas y mayores */
  }
}
</style>
  