<template>
     <router-link to="/photos">
      <button type="button" class="btn btn-outline-secondary">
        Volver
      </button>
    </router-link>
  <div class="container">
    <!-- <h2>{{ currentAlbum.title }}</h2> -->
    <div class="row mt-5">
      <div class="col-md-4 mb-4" v-for="(image, index) in currentAlbum.images" :key="index">
        <img :src="image" class="img-fluid" alt="">
      </div>
    </div>
  </div>
</template>
  
<script>
 import logoAtrezzo from "../assets/logo-atrezzo.png";
 import albums from "../data/albums";

export default {
  name: "AlbumDetails",
  data() {
    return {
      logoAtrezzo,
      albums: albums,
    };
  },
  computed: {
    currentAlbum() {
      const id = parseInt(this.$route.params.id, 10);
      return this.albums[id] || {};
    },
  },
};
</script>
  
<style scoped>
.btn {
  margin: 20px 0 0 20px;
}
  .logo {
    margin: 0 auto;
    text-align: center;
    max-width: 300px;
    margin-bottom: 50px;
  }
</style>
