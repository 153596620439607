
/* eslint-disable vue/multi-word-component-names */

import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
import Vue3Video from 'vue3-video'; // Importa Vue3Video
import router from './router';
import "@/assets/css/swiper-bundle.min.css"; // Importa aquí el CSS de Swiper

SwiperCore.use([Autoplay, Navigation]);

const app = createApp(App);
app.component("Swiper", Swiper);
app.component("SwiperSlide", SwiperSlide);

app.use(Vue3Video);
app.use(router);

app.mount('#app');