const propuesta = 
    {
        title: "Propuesta La Konga",
        thumbnail: "https://picsum.photos/id/1003/500/300",
        
        images: [
          "https://picsum.photos/id/1000/1000/600",
          "https://picsum.photos/id/1001/1000/600",
          "https://picsum.photos/id/1002/1000/600",
          "https://picsum.photos/id/1000/1000/600",
          "https://picsum.photos/id/1001/1000/600",
          "https://picsum.photos/id/1002/1000/600",
          "https://picsum.photos/id/1000/1000/600",
          "https://picsum.photos/id/1001/1000/600",
          "https://picsum.photos/id/1002/1000/600",
        ],
      }

export default propuesta;

