<template>
    <div class="container">
        <router-link to="/">
      <button type="button" class="btn btn-outline-secondary">
        Volver
      </button>
    </router-link>
      <div class="row">
        <div class="col-12 col-md-4" v-for="(album, index) in albums" :key="index">
          <div class="card bg-card text-white" @click="openAlbum(index)">
            <img :src="album.thumbnail" class="card-img" :alt="album.title" />
            <div class="card-img-overlay d-flex align-items-center justify-content-center">
              <h5>{{ album.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

import albums from "../data/albums";
import logoAtrezzo from "../assets/logo-atrezzo.png";


  export default {
    name: "AlbumsPage",
    data() {
      return {
        logoAtrezzo,
        albums: albums,
        currentAlbum: {},
      };
    },
    methods: {
      openAlbum(index) {
        this.$router.push({ name: "Album", params: { id: index } });
      },
    },
  };
  </script>
  
  <style scoped>

.btn {
  margin: 20px 0 0 20px;
}
  .logo {
    margin: 0 auto;
    text-align: center;
    max-width: 300px;
    margin-bottom: 50px;
  }
  
  .card-img {
    filter: brightness(0.4);
  }
  
  .bg-card {
    cursor: pointer;
    margin-top: 1rem;
    transition: transform 0.3s;
  }
  
  .bg-card:hover {
    transform: scale(1.05);
  }
  </style>
  