<template>
    <div class="container">
        <router-link to="/">
      <button type="button" class="btn btn-outline-secondary">
        Volver
      </button>
    </router-link>
      <div class="row">
        <div class="col-12 col-md-4" v-for="video in videos" :key="video.id">
          <div class="card bg-card text-white" @click="openVideo(video.id)">
            <img :src="video.thumbnail" class="card-img" :alt="video.title" />
            <div class="card-img-overlay d-flex align-items-center justify-content-center">
              <h5>{{ video.title }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  
  
  <script>
      import logoAtrezzo from "../assets/logo-atrezzo.png"

  export default {
    name: "VideosPage",
    data() {
      return {
        logoAtrezzo,
        videos: [
          {
            id: "cEq-z6wnbck",
            title: "UES21- PLENARIO DOCENTE 2023",
            thumbnail: "https://img.youtube.com/vi/cEq-z6wnbck/0.jpg",
          },
          {
            id: "USNFbjrpjyc",
            title: "AJI Muebles Picantes",
            thumbnail: "https://img.youtube.com/vi/USNFbjrpjyc/0.jpg",
          },
          {
            id: "GFGoBAo2lkI",
            title: "EXPO ESTILO CASA 2022",
            thumbnail: "https://img.youtube.com/vi/GFGoBAo2lkI/0.jpg",
          },
          {
            id: "pCrsF8QC4Pc",
            title: "STOLLER CIERRE 2022",
            thumbnail: "https://img.youtube.com/vi/pCrsF8QC4Pc/0.jpg",
          },
          {
            id: "Apz89C1VPjo",
            title: "UES21 - COSQUIN ROCK 2023",
            thumbnail: "https://img.youtube.com/vi/Apz89C1VPjo/0.jpg",
          },
          {
            id: "WWV0A9Sm2HE",
            title: "DERECHO AL VERANO - Secretaria de Salud",
            thumbnail: "https://img.youtube.com/vi/WWV0A9Sm2HE/0.jpg",
          },
          {
            id: "BVn1w1V0ubM",
            title: "BACKSTAGE Desfile Real 2021",
            thumbnail: "https://img.youtube.com/vi/BVn1w1V0ubM/0.jpg",
          },
          // Agrega más videos aquí
        ],
      };
    },
    methods: {
      openVideo(videoId) {
        window.open(`https://www.youtube.com/watch?v=${videoId}`, "_blank");
      },
    },
  };
  </script>
  
  <style scoped>
  .btn {
  margin: 20px 0 0 20px;
}
    .logo {
        margin: 0 auto;
    text-align: center;
    max-width: 300px;
    margin-bottom: 50px;
  }
  .card-img {
    filter: brightness(0.4);
  }
  
  .bg-card {
    cursor: pointer;
    margin-top: 1rem;
    transition: transform 0.3s;
  }
  
  .bg-card:hover {
    transform: scale(1.05);
  }
  </style>
  