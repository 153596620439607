<template>
  <div id="app">
    <div class="page-container">
      <div class="content">
        <router-view></router-view>
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<script>

import AppFooter from "../src/components/Footer.vue";

export default {
  name: 'App',
  components: {AppFooter},
};
</script>

<style>
  body,
  html {
    height: 100%;
    margin: 0;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .content {
    flex-grow: 1;
    margin-bottom: 100px; /* Ajusta este valor según la altura de tu footer */
  }
</style>