<template>
    <div class="container">
        <router-link to="/clients">
            <button type="button" class="btn btn-outline-secondary">Volver</button>
        </router-link>
    </div>

</template>

<script>
    export default{
        name: "ClientsPage",
        data(){
            return {
                
            }
        }

    }
</script>

<style>

</style>