
function importUlisesImages() {
    const context = require.context("../assets/UlisesBcn", false, /\.jpg$/);
    console.log('Context:', context);
    console.log('Context keys:', context.keys());
    const images = context.keys().reduce((images, key) => {
      const imgKey = key.replace(/^\.\//, "").replace(/\.jpg$/, "");
      images[imgKey] = context(key);
      return images;
    }, {});
  
    console.log('Ulises images:', images);
    return images;
  }
  
  export { importUlisesImages };


function importBackstageImages() {
    const context = require.context("../assets/Backstage", false, /\.jpg$/);
    console.log('Context:', context);
    console.log('Context keys:', context.keys());
    const images = context.keys().reduce((images, key) => {
      const imgKey = key.replace(/^\.\//, "").replace(/\.jpg$/, "");
      images[imgKey] = context(key);
      return images;
    }, {});
  
    console.log('Backstage images:', images);
    return images;
  }
  
  export { importBackstageImages };


function importCosquinRockImages() {
    const context = require.context("../assets/cosquinRock", false, /\.jpg$/);
    console.log('Context:', context);
    console.log('Context keys:', context.keys());
    const images = context.keys().reduce((images, key) => {
      const imgKey = key.replace(/^\.\//, "").replace(/\.jpg$/, "");
      images[imgKey] = context(key);
      return images;
    }, {});
  
    console.log('Cosquin Rock images:', images);
    return images;
  }
  
  export { importCosquinRockImages };


function importManantialesImages() {
    const context = require.context("../assets/Manantiales", false, /\.jpg$/);
    console.log('Context:', context);
    console.log('Context keys:', context.keys());
    const images = context.keys().reduce((images, key) => {
      const imgKey = key.replace(/^\.\//, "").replace(/\.jpg$/, "");
      images[imgKey] = context(key);
      return images;
    }, {});
  
    console.log('Manantiales images:', images);
    return images;
  }
  
  export { importManantialesImages };